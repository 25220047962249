.preview[data-v-8f276c96] {
  width: 100%;
}
.previewTitle[data-v-8f276c96] {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}