[data-v-5a5c4fa8] .el-button + .el-button {
  margin-left: 10px;
}
[data-v-5a5c4fa8] .ossBox .el-button span {
  margin: 0 !important;
}
[data-v-5a5c4fa8] .ossBox .cell .el-button {
  width: 24px;
}
[data-v-5a5c4fa8] .ossBox .cell .el-button i {
  display: none;
}