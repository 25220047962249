.topContainer[data-v-87cb5f06] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #fff;
  width: 100%;
  height: 50px;
  text-align: right;
}