[data-v-695d4797].uploadhideAdd .el-upload--picture-card {
  display: none;
}
.disUoloadSty[data-v-695d4797] .el-upload--picture-card {
  display: none;
}
.disUoloadSty[data-v-695d4797] .el-upload__tip {
  display: none;
}
.disUoloadSty[data-v-695d4797] .el-upload--text {
  display: none;
}