[data-v-23b828f2] .el-button + .el-button {
  margin-left: 10px;
}
[data-v-23b828f2] .smsBox .el-button span {
  margin: 0 !important;
}
[data-v-23b828f2] .smsBox .cell .el-button {
  width: 24px;
}
[data-v-23b828f2] .smsBox .cell .el-button i {
  display: none;
}