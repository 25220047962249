[data-v-4eb85823] .el-tabs__item {
  padding: 0 20px;
  height: 40px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  line-height: 40px;
  display: inline-block;
  list-style: none;
  font-size: 16px;
  font-weight: 500;
  position: relative;
}
.dashboard-container[data-v-4eb85823] {
  width: 100%;
  height: 100%;
  background: #ebeef5;
  position: relative;
}
.dashboard-container .custom-page[data-v-4eb85823] {
  width: 100%;
  height: 100%;
}
.dashboard-container[data-v-4eb85823] .layout-area {
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
}
.dashboard-container .setting-btn[data-v-4eb85823] {
  position: absolute;
  top: 200px;
  right: -10px;
  height: 40px;
  width: 40px;
  text-align: center;
  padding: 0;
  border-radius: 20px 0 0 20px;
  z-index: 100;
}
.dashboard-container .setting-btn[data-v-4eb85823] i {
  font-size: 20px;
  font-weight: 580;
}
.dashboard-container[data-v-4eb85823] .vue-grid-layout {
  margin: -10px;
}
.dashboard-container[data-v-4eb85823] .el-scrollbar__view {
  overflow: hidden;
}
.dashboard-container .img1[data-v-4eb85823] {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}