[data-v-6b5ae32f] .el-tabs__content {
  padding: 0px !important;
}
.img-list[data-v-6b5ae32f] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.img-list[data-v-6b5ae32f] .singleImg-container {
  margin-right: 20px;
}
.img-list[data-v-6b5ae32f] .singleImg-container :last-child {
  margin-right: 0;
}
.saveBtn[data-v-6b5ae32f] {
  width: 220px;
}
[data-v-6b5ae32f] .avue-upload__icon {
  line-height: 178px !important;
}
.electronicSignatureImg[data-v-6b5ae32f] {
  width: 1000px;
  height: 600px;
  display: block;
}