.formBox[data-v-809ff2ac] {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.formTitleBox[data-v-809ff2ac] {
  width: calc(100% - 24px);
  border-bottom: 1px solid #bbbbbb;
  padding: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.leftBack[data-v-809ff2ac] {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.backBox[data-v-809ff2ac] {
  position: relative;
  line-height: 28px;
}
.backBox[data-v-809ff2ac]:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 16px;
  right: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #dcdfe6;
}
.rightBtn[data-v-809ff2ac] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.formContentBox[data-v-809ff2ac] {
  padding: 12px;
  height: calc(100% - 94px);
}
.formMain[data-v-809ff2ac] {
  width: 100%;
  height: 100%;
  overflow: auto;
  height: calc(100% - 28px);
}
.formTopic[data-v-809ff2ac] {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}
.shuttleBackBox[data-v-809ff2ac] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.shutleBack[data-v-809ff2ac] {
  width: 27%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}
.leftRightBtn[data-v-809ff2ac] {
  margin: 211px 12px;
}
.shutleTitle[data-v-809ff2ac] {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}
.shutContent[data-v-809ff2ac] {
  padding: 6px;
  height: 400px;
  overflow: auto;
}
.shutImg[data-v-809ff2ac] {
  width: 40px;
  height: 40px;
  display: block;
  margin: 207px 12px;
}
.deptBox[data-v-809ff2ac] {
  height: 40px;
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.leftClass[data-v-809ff2ac] {
  width: calc(15% - 48px);
  border: 1px solid #bbbbbb;
  border-radius: 4px;
  padding: 12px 24px;
}
.classDataBox[data-v-809ff2ac] {
  height: calc(100% - 34px);
  overflow: auto;
}
.classLittle[data-v-809ff2ac] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: calc(100% - 14px);
  border: 1px solid #bbbbbb;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 4px;
  margin: 12px 0;
  cursor: pointer;
  font-size: 15px;
  padding: 0 6px;
}
.rightTable[data-v-809ff2ac] {
  width: 84%;
}
.classActive[data-v-809ff2ac] {
  background-color: #E9EDF3;
}