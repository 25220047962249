.el-input-group__append[data-v-651e6d3c] {
  display: none;
}
.leftNavigation[data-v-651e6d3c] {
  width: 200px;
  margin-right: 5px;
  height: calc(100vh - 150px);
  background-color: #ffffff;
  overflow: auto;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.buttonBox[data-v-651e6d3c] {
  width: calc(100% - 20px);
  background-color: #ffffff;
  margin-bottom: 5px;
  padding: 5px 10px;
}
.crumbsBox[data-v-651e6d3c] {
  width: calc(100% - 20px);
  background-color: #ffffff;
  margin-bottom: 5px;
  padding: 5px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 31px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.crumsPlate[data-v-651e6d3c] {
  line-height: 31px;
}
.exhibitionBox[data-v-651e6d3c] {
  width: 100%;
  height: calc(93% - 5px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.paginationBox[data-v-651e6d3c] {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.detailsRightTitle[data-v-651e6d3c] {
  width: 100%;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
}
.detailsRightBox[data-v-651e6d3c] {
  width: 100%;
}
.informationBox[data-v-651e6d3c] {
  width: calc(28% - 20px);
  height: calc(100% - 10px);
  background-color: #ffffff;
  padding: 16px 10px 5px 10px;
}
.tabelBox[data-v-651e6d3c] {
  width: 70%;
  height: 100%;
  padding: 5px 10px;
  background-color: #ffffff;
  position: relative;
}
.fileContainer[data-v-651e6d3c] {
  width: 100%;
  height: 100%;
}
.netWorkBox[data-v-651e6d3c] {
  width: 100%;
  height: 670px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow: auto;
}
.netWork[data-v-651e6d3c] {
  width: 18%;
  height: 140px;
  cursor: pointer;
  text-align: center;
  font-size: 15px;
  color: #333333;
  margin: 1%;
}
.netWorkImg[data-v-651e6d3c] {
  width: 75px;
  height: 75px;
  display: block;
  margin: auto;
}
.netWork p[data-v-651e6d3c] {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.netWorkActive[data-v-651e6d3c] {
  background-color: #ecf5ff;
}
.el-menu-item.is-active[data-v-651e6d3c] {
  color: #FFFFFF;
}