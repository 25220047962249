[data-v-0f2f924f] .el-steps--simple {
  background: #FFFFFF;
  border-radius: 0;
  border-bottom: 1px solid #e4e7ed;
}
[data-v-0f2f924f] .bjs-powered-by {
  display: none;
}
[data-v-0f2f924f] #pane-process {
  background-color: #FFFFFF;
}
[data-v-0f2f924f] #pane-node {
  background-color: #FFFFFF;
}
[data-v-0f2f924f] .djs-palette {
  width: 94px !important;
}
.foot-item[data-v-0f2f924f] {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 101;
  height: 66px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.avue-form[data-v-0f2f924f] {
  margin-bottom: 66px;
}
.wf-design[data-v-0f2f924f] {
  border-radius: 4px;
  border: none;
}
.wf-design .avue-group__title[data-v-0f2f924f] {
  margin-top: 8px;
}
.bjs-container .bjs-powered-by[data-v-0f2f924f] {
  display: none;
}
.el-collapse[data-v-0f2f924f] {
  padding: 0 8px;
  background-color: #FFFFFF;
}
.el-aside[data-v-0f2f924f] {
  width: auto !important;
  overflow: hidden !important;
}
.el-tabs__content[data-v-0f2f924f] {
  overflow-y: auto !important;
}
.avue-form__item-- .el-form-item__content[data-v-0f2f924f] {
  height: 30vh;
  overflow: auto;
}
[data-v-0f2f924f] .avue-dynamic {
  height: calc(100vh - 505px) !important;
  overflow: auto;
}