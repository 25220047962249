.wf-dialog {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -40%);
          transform: translate(-50%, -40%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
.wf-dialog .el-dialog__body {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: auto;
}