.main[data-v-72765aa3] {
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0 0 10px;
}
.main .JNPF-common-search-box[data-v-72765aa3] {
  border-bottom: 1px solid #dcdfe6;
}
.main[data-v-72765aa3] .el-table {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  border-top: none;
}