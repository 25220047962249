[data-v-28a3d318] .bjs-powered-by {
  display: none;
}
[data-v-28a3d318] .el-tabs__content {
  display: none;
}
[data-v-28a3d318] .el-tabs--border-card {
  border-bottom: 0;
}
.center[data-v-28a3d318] {
  padding: 0 !important;
}
.wf-container .el-collapse-item__content[data-v-28a3d318] {
  padding-top: 8px;
}
.wf-container .el-badge__content.is-fixed[data-v-28a3d318] {
  right: 16px;
}
.wf-dialog[data-v-28a3d318] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 40%;
  left: 50%;
  -webkit-transform: translate(-50%, -40%);
          transform: translate(-50%, -40%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
.wf-dialog .el-dialog__body[data-v-28a3d318] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow: auto;
}