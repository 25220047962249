.add-dialog[data-v-935b85aa] .el-dialog__header {
  display: none !important;
}
.add-dialog[data-v-935b85aa] .el-dialog__body {
  padding: 40px 30px !important;
}
.add-main[data-v-935b85aa] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.add-main .add-main-part[data-v-935b85aa] {
  border: 1px solid #dcdfe6;
  padding: 20px;
  border-radius: 4px;
}
.add-main .add-main-cap[data-v-935b85aa] {
  line-height: 25px;
  font-size: 18px;
  color: #303133;
  margin-bottom: 14px;
  font-weight: 400;
}
.add-main .add-main-container[data-v-935b85aa] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.add-main .add-main-left[data-v-935b85aa] {
  width: 530px;
}
.add-main .add-main-left .add-item[data-v-935b85aa] {
  background: #f1f5ff;
}
.add-main .add-main-left .add-item .add-icon[data-v-935b85aa] {
  background: #ccd9ff;
  color: #537eff;
}
.add-main .add-item[data-v-935b85aa] {
  width: 235px;
  height: 120px;
  background: #fef3e6;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  border-radius: 4px;
  padding-left: 12px;
}
.add-main .add-item .add-icon[data-v-935b85aa] {
  width: 48px;
  height: 48px;
  margin-right: 10px;
  background: #fce1bf;
  border-radius: 6px;
  color: #ea986c;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  font-size: 30px;
  line-height: 48px;
  text-align: center;
}
.add-main .add-item .add-txt[data-v-935b85aa] {
  height: 48px;
}
.add-main .add-item .add-txt P[data-v-935b85aa] {
  line-height: 28px;
}
.add-main .add-item .add-txt .add-title[data-v-935b85aa] {
  font-size: 18px;
  color: #606266;
}
.add-main .add-item .add-txt .add-desc[data-v-935b85aa] {
  color: #8d8989;
  font-size: 12px;
}