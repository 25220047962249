.formContentBox[data-v-0c53b7a1] {
  padding: 12px 12px 0;
  height: calc(100% - 76px);
  overflow-y: scroll;
}
.disUoloadSty[data-v-0c53b7a1] .el-upload--picture-card {
  display: none;
}
.disUoloadSty[data-v-0c53b7a1] .el-upload__tip {
  display: none;
}
.disUoloadSty[data-v-0c53b7a1] .el-upload--text {
  display: none;
}