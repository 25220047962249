
.scopeWrap[data-v-19a0bbc8]{
  padding-bottom: 0px;
  background: #ffffff;
}
.scopeForm[data-v-19a0bbc8]{
  width: 100%;
  background: #ffffff;
}
.deleteForm[data-v-19a0bbc8]{
  width: 100%;
  height: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  textalign: center;
  cursor: pointer;
  color: #ebebeb;
}
.addWrap[data-v-19a0bbc8]{
  width: 100%;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #ffffff;
  textalign: center;
  line-height: 40px !important;
  border: 2px dashed #e6e6e6;
  cursor: pointer;
}
