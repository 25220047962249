.main[data-v-f95973f4] {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: #606266;
}
.main .flow-tabs[data-v-f95973f4] .el-tabs__item {
  text-align: left !important;
  width: 160px !important;
}
.main .flow-tabs[data-v-f95973f4] .el-tabs__content {
  height: 100%;
}
.main .flow-tabs[data-v-f95973f4] .el-tabs__content .el-tab-pane {
  height: 0;
  display: none !important;
}
.main[data-v-f95973f4] .is-horizontal {
  display: none;
}
.main[data-v-f95973f4] .el-scrollbar__view {
  overflow: hidden;
}
.main .box[data-v-f95973f4] {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.main .list[data-v-f95973f4] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding-top: 10px;
  overflow: hidden auto;
}
.main .list .item[data-v-f95973f4] {
  margin-bottom: 20px;
  cursor: pointer;
}
.main .list .item[data-v-f95973f4] .el-card {
  border-radius: 10px;
}
.main .list .item[data-v-f95973f4] .el-card__body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 15px;
}
.main .list .item .box-icon[data-v-f95973f4] {
  width: 48px;
  height: 48px;
  border-radius: 10px;
  text-align: center;
  background-color: #ccc;
  display: inline-block;
  margin-right: 15px;
}
.main .list .item .box-icon i[data-v-f95973f4] {
  text-align: center;
  font-size: 36px;
  color: #fff;
  line-height: 48px;
}
.main .list .item .title[data-v-f95973f4] {
  display: inline-block;
  width: calc(100% - 63px);
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  font-size: 14px;
}